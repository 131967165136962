.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 40px 0;
}

.figure-image {
  display: contents;
}

.figure-image img {
  border-radius: var(--rounded);
  overflow: hidden;
  box-shadow: var(--shadow);
}

.figure-caption {
  font-style: italic;
  text-align: center;
}
